<template>
  <div class='main'>
    <section class='section is-medium has-text-centered is-flex is-flex-direction-column is-align-items-center'>
      <h1 class='title is-1 has-text-white'>Zalgo - Multipurpose bot</h1>
      <p class='desc is-4'>
        Zalgo a French Discord bot that offering many features to automate and protect your server at 99% !
        French and English version are available !
      </p>
    </section>
    <div class='main-stats'>
      <TopPath />
      <section class='section is-small main_stats-section'>
        <p class='main_stats-title has-text-white has-text-centered'>Statistics</p>
        <div class='level pb-5'>
          <div class='level-item has-text-centered'>
            <div>
              <p class='heading main-stats-name'>Servers</p>
              <p class='title main-stats-value'>+ {{ guilds }}</p>
            </div>
          </div>
          <div class='level-item has-text-centered'>
            <div>
              <p class='heading main-stats-name'>Users</p>
              <p class='title main-stats-value'>+ {{ users }}</p>
            </div>
          </div>
          <div class='level-item has-text-centered'>
            <div>
              <p class='heading main-stats-name'>Shards</p>
              <p class='title main-stats-value'>{{  shards.length }}</p>
            </div>
          </div>
        </div>
        <p class='main_stats-title has-text-centered'>What is this bot?</p>
        <div class='has-text-centered'>
          <h4 class='subtitle has-text-white desc'>
            Zalgo is a multipurpose bot based on management and automatic management.
            It protects servers, makes logs, gives statistics and information, rewards member activity, and more..
          </h4>
        </div>
      </section>
      <BottomPath />
    </div>
    <section class='section is-medium section-fonc'>
      <div class='columns is-multiline is-align-items-center'>
        <div class='column is-half'>
          <h3 class='title is-1'>Embeds creator</h3>
          <p class='subtitle is-5'>
            Create your own messages with the embeds on Discord.
          </p>
        </div>
        <div class='column is-half'>
          <img src='/img/embeds.gif' alt='Embeds creator on Discord' />
        </div>
      </div>
      <div class='columns is-multiline is-align-items-center'>
        <div class='column is-half'>
          <img src='/img/levels.webp' alt='Levels system on Discord' />
        </div>
        <div class='column is-half'>
          <h3 class='title is-1'>Levels system</h3>
          <p class='subtitle is-5'>
            Give value to members who chat on your community server with ranks and levels.
          </p>
        </div>
      </div>
      <div class='columns is-multiline is-align-items-center'>
        <div class='column is-half'>
          <h3 class='title is-1'>Roles</h3>
          <p class='subtitle is-5'>
            Assign roles to your members with simple buttons. Easy to set up!
          </p>
        </div>
        <div class='column is-half'>
          <img src='/img/roles.gif' alt='Roles system on Discord' />
        </div>
      </div>

    </section>
  </div>
</template>
<script>
import TopPath from '../components/paths/Top.vue';
import BottomPath from '../components/paths/Bottom.vue';
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    TopPath,
    BottomPath
  },
  data() {
    return {
      guilds: 0,
      users: 0,
      shards: []
    };
  },
  mounted() {
    axios.get('https://websiteapi.zalgo.fr/stats', {}).then(res => {
      for (let i = 0; i < res.data.data.shards.length; i++) {
        this.guilds += res.data.data.shards[i].serverCount;
      }
      for (let i = 0; i < res.data.data.shards.length; i++) {
        this.users += res.data.data.shards[i].usersCount;
      }
      this.shards = res.data.data.shards;
    }).catch(err => {
      this.guilds = '6 900';
      this.users = '900 000';
      console.log(err);
    });
  },
};
</script>
<style scoped>
.main_stats-title {
  color: white;
  margin-bottom: 50px;
  font-size: 3.7rem;
}

.main_stats-section {
  background-color: #5865f2;
  margin: -10px 0;
}

.main-stats .main-stats-name {
  color: white;
  font-size: 2rem;
}

.main-stats .main-stats-value {
  color: #171717;
  font-size: 1.8rem;
}

.section-fonc p,
.section-fonc h3 {
  color: white;
}

.section-fonc > .columns {
  padding-top: 10rem;
}
.desc {
  font-size: 1.7rem;
  line-height: 2.2rem;
  max-width: 60%;
  margin: auto;
}


@media (max-width: 768px) {
  .section-fonc > .columns {
    padding-top: 3rem;
  }

  .section-fonc > .columns:nth-child(2) {
    display: flex;
    flex-direction: column-reverse;
  }
  .desc {
    max-width: unset;
    margin: 1rem auto;
  }
}

.section-fonc img {
  border-radius: 1rem;
}

.section-fonc p {
  padding-top: 2rem;
  font-size: 1.6rem;
}

@media screen and (max-width: 1023px) {
  .home-title {
    font-size: 2rem;
  }

  .home-description {
    max-width: 25em;
    font-size: 16px;
    line-height: 1.8rem;
  }

  .main-stats .main_stats-title {
    font-size: 34px;
    margin-bottom: 30px;
  }

  .main-stats .main-stats-name {
    font-size: 28px;
    margin-bottom: 14px;
  }

  .main-stats .main-stats-value {
    font-size: 26px;
  }
}
</style>
