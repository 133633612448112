import store from '../store/index';
import { createWebHistory, createRouter } from 'vue-router';

//Import components/views
import Home from '../views/Home.vue';
import Guild from '../views/Guild.vue';
import Dashboard from '../views/Dashboard.vue';
import Staff from '../views/Staff.vue';
import { END_LOADING, FETCH_GUILD, FETCH_USER, IS_LOGGED, LOGOUT, START_LOADING } from '../store/types';
import Invite from '../views/Invite';
import Vote from '../views/Vote';
import Status from '../views/Status';
import Terms from '../views/Terms';
import Privacy from '../views/Privacy';

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    name: 'Invite',
    path: '/invite',
    component: Invite
  },
  {
    name: 'Add the bot',
    path: '/addbot',
    component: Invite
  },
  {
    name: 'Staff',
    path: '/staff',
    component: Staff
  },
  {
    name: 'Service status',
    path: '/status',
    component: Status
  },
  {
    name: 'Vote for the bot',
    path: '/vote',
    component: Vote
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    props: true
  },
  {
    name: 'Support server',
    path: '/servers/:guildID',
    component: Guild,
    props: true
  },
  {
    name: 'Login',
    path: '/login'
  },
  {
    name: 'Logout',
    path: '/logout'
  },
  {
    name: 'Terms',
    path: '/terms',
    component: Terms
  },
  {
    name: 'Privacy',
    path: '/privacy',
    component: Privacy
  }
];

const router = createRouter({
  routes,
  history: createWebHistory()
});

router.beforeEach((to, from, next) => {
  switch (to.name) {
    //Dashboard routes
    case 'Dashboard':
      if (to.query.code && !store.getters[IS_LOGGED]) {
        store.commit(START_LOADING);
        store
          .dispatch(FETCH_USER, to.query.code)
          .then(() => {
            next('/dashboard');
            store.commit(END_LOADING);
          })
          .catch(() => {
            next('/login');
          });
      } else if (!store.getters[IS_LOGGED]) {
        router.push('/login');
      } else next();
      break;

    case 'Server':
      if (to.query.code && to.query.guild_id) {
        store.commit(START_LOADING);
        //fetch new guild
        store.commit(END_LOADING);
      } else if (!store.getters[IS_LOGGED]) {
        return router.push('/login');
      } else if (!to.params.guildID) {
        return router.push('/dashboard');
      } else {
        store.commit(START_LOADING);

        store.dispatch(FETCH_GUILD, to.params.guildID).then(() => {
          next();
          store.commit(END_LOADING);
        }).catch(() => {
          window.location.href = `https://discord.com/oauth2/authorize?client_id=1017058855483613266&permissions=8&redirect_uri=https%3A%2F%2Fwebsiteapi.zalgo.fr%2Fguilds&response_type=code&scope=bot%20guilds%20identify&disable_guild_select=true&guild_id=${to.params.guildID}`;
        });

      }
      break;
    //Auth routes
    case 'Login':
      if (!store.getters[IS_LOGGED]) {
        window.location.href = 'https://websiteapi.zalgo.fr/login';
      } else {
        next(from.path);
      }
      break;
    case 'Logout':
      store.commit(LOGOUT);
      next('/');
      break;
    default:
      next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name) {
    to.name = to.name.charAt(0).toUpperCase() + to.name.slice(1);
    document.title = `Zalgo - ${to.name}`;
  } else {
    document.title = 'Zalgo';
  }

  next();
});

export default router;
