<template>
  <section>
    <Navbar />
    <div v-if='loading' id='page'> <!-- :class="{ 'is-blurred': showNavbar }" -->
      <p>Chargement en cours...</p>
    </div>
    <div v-else id='page'>
      <router-view></router-view>
      <Footer />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHOW_NAVBAR, LOADING } from './store/types';

import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  computed: {
    ...mapGetters({
      showNavbar: SHOW_NAVBAR,
      loading: LOADING
    })
  }
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  background-color: #171717;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #171717;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #171717;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6868684f;
}

@media screen and (max-width: 1023px) {
  .is-blurred {
    filter: blur(1.5rem);
    opacity: 1;
  }
}

p {
  color: white;
}

@font-face {
  font-family: 'MPLUSRounded1c';
  src: url('/public/fonts/MPLUSRounded1c-Bold.ttf') format('truetype');
}

h1, h2, h3, h4, h5, h6, p, nav {
  font-family: 'MPLUSRounded1c', sans-serif;
}

a {
  transition: .3s;
}


.navbar-menu.is-active {
  display: block;
  background-color: #171717;
  width: 100%;
  box-shadow: 3px 6px 7px #080808;
}

</style>
