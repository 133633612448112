<template>
  <nav class='navbar' role='navigation' aria-label='main navigation'>
    <div class='container'>
      <div class='navbar-brand'>
        <router-link class='navbar-item' to='/'>
          <div class='navbar_brand-logo'>Zalgo</div>
        </router-link>

        <a id='navbar-burger-button' role='button'
           class='navbar-burger' :class="{ 'is-active': showNavbar }"
           @click='displayNavbar()' aria-label='menu' aria-expanded='false' data-target='main-navbar-menu'>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </a>
      </div>

      <div id='main-navbar-menu' class='navbar-menu' :class="{ 'is-active': showNavbar }">
        <div class='navbar-start'>
          <router-link class='navbar-item' to='/invite'>Invite</router-link>
          <a class='navbar-item' href='https://discord.gg/3w3PVRTqdH' target='_blank'>Support</a>
          <router-link class='navbar-item' to='/status'>Status</router-link>
          <router-link class='navbar-item' to='/staff'>Staff</router-link>
        </div>
        <div class='navbar-end'>
          <div class='navbar-item'>
            <router-link to='/login' v-if='!user' id='navbar-login-button' class='button is-rounded'>Login</router-link>
            <div v-else class='dropdown is-hoverable'>
              <div class='dropdown-trigger'>
                <button class='button' aria-haspopup='true' aria-controls='dropdown-menu4'
                        style='background-color: #5865f2; color: white; border: none'>
                  <span>{{ user.username }}#{{ user.discriminator }}</span>
                  <img style='margin-left: 5px; border-radius: 50%'
                       :src='`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=2048`' />
                </button>
              </div>
              <div class='dropdown-menu' id='dropdown-menu4' role='menu'>
                <div class='dropdown-content'
                     style='background-color: #171717; color: white; border: 1px solid #111111'>
                  <router-link to='/dashboard'>
                    <div class='dropdown-item'>
                      <p>Vos serveurs</p>
                    </div>
                  </router-link>
                  <router-link to='/logout'>
                    <div class='dropdown-item'>
                      <p style='color: red'>Logout</p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { USER, SHOW_NAVBAR, SET_NAV } from '../store/types';

export default {
  name: 'Navbar',
  methods: {
    ...mapMutations({
      displayNavbar: SET_NAV
    })
  },
  computed: {
    ...mapGetters({
      user: USER,
      showNavbar: SHOW_NAVBAR
    })
  }
};
</script>

<style scoped>
/** Navbar */
.navbar {
  background-color: transparent;
}
.navbar_brand-logo,
.navbar-item {
  transition: .3s;
}
.navbar_brand-logo {
  font-size: 1.5rem;
  color: white;
}

.navbar_brand-logo:hover {
  color: #5865f2;
}

.navbar-burger span {
  color: white;
}

.navbar-item {
  color: white;
  padding: 1.2rem;
}

.navbar-start a:hover,
.navbar-start a:hover,
.navbar-start a:focus {
  color: #5865f2;
  background-color: transparent;
}

.navbar-start {
  flex-grow: 1;
  justify-content: center;
}

#navbar-login-button {
  color: white;
  background-color: #5865f2;
  border: #5865f2 solid 1px;
}

#navbar-login-button:hover {
  background-color: transparent;
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    position: absolute;
    z-index: 999;
    padding: 3rem 1rem;
    background-color: transparent;
    box-shadow: none;
  }
}
</style>
