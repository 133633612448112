<template>
  <div id='main'>
    <h1>Redirect to Discord</h1>
    <meta http-equiv='refresh' content='0;
          url=https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D710135804714024962%26permissions%3D8%26scope%3Dapplications.commands%2520bot'>
  </div>
</template>
<script>
import TopPath from '../components/paths/Top.vue';
import BottomPath from '../components/paths/Bottom.vue';

export default {
  name: 'Invite',
  components: {
    TopPath,
    BottomPath
  }
};
</script>
<style scoped>

</style>
