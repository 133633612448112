<template>
    <div id="main">
        <div v-if="actual">
            <h1>{{ actual.guild.name }}</h1>
            <h1>{{ actual.guild.channels.length }} salons</h1>
            <div class="box">
                Logs Channels
                <ChannelsManager types="[text]" />
            </div>
        </div>
        <div v-else class="has-text-centered">
            <section class="section is-medium">
                <h1 class="title" style="color: white">Le bot n'est pas sur ce serveur</h1>
                <div class="buttons">
                    <a class="button is-success" :href="`https://discord.com/api/oauth2/authorize?client_id=1017058855483613266&permissions=8&scope=bot${$route.params.guildID ? `&guild_id=${$route.params.guildID}` : null}`" target="_blank">Ajouter</a>
                    <router-link :to="`/servers/${$route.params.guildID}`" class="button is-success">Actualiser</router-link>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ACTUAL_GUILD } from "../store/types";
import ChannelsManager from "../components/forms/ChannelsManager.vue";

export default {
    name: "Guild",
    computed: {
        ...mapGetters({
            actual: ACTUAL_GUILD,
        }),
    },
    components: {
        ChannelsManager,
    },
};
</script>
