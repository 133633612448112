<template>
    <div class="test">

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ACTUAL_GUILD } from "../../store/types";

export default {
    name: "ChannelsManager",
    props: ["types"],
    computed: {
        ...mapGetters({
            actual: ACTUAL_GUILD
        })
    },
};
</script>
