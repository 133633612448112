<template>
  <div id='main'>
    <div class='container'>
      <section class='section is-medium'>
        <p class='title is-2 has-text-centered has-text-white'>Vos serveurs</p>
        <div class='grid'>
          <div v-for='guild in guilds' :key='guild.id'>
            <router-link :to='`/servers/${guild.id}`'>
              <div class='box zalgo-box'>
                <figure class='image is-128x128'>
                  <img class='is-rounded' :src='guild.icon' :alt='guild.name' />
                </figure>
                <div class='card-info'>
                  <p class='title is-4 has-text-white'>{{ guild.name }}</p>
                  <p class='subtitle is-6 has-text-white'>{{ guild.id }}</p>
                </div>

              </div>
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { GUILDS } from '../store/types';

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters({
      guilds: GUILDS
    })
  }
};
</script>
<style scoped>
.grid {
  display: grid;
  margin: 6rem auto;
  max-width: 1200px;
  gap: 1rem;
  grid-auto-columns: 1fr;
  grid-template-areas:
        "server server server server";
}

@media (max-width: 1200px) {
  .grid {
    grid-template-areas:
            "server server";
  }
}

@media (max-width: 900px) {
  .grid {
    grid-template-areas:
            "server";
  }
}

.card-info {
  padding-top: 2rem;
}
.zalgo-button {
  color: white;
  background-color: #5865f2;
  border-color: #5865f2;
}

.zalgo-user-avatar {
  height: 175px;
  width: auto;
  border-radius: 50%;
  border: 1px solid #5865f2;
}

.zalgo-button:hover {
  transform: scale(1.025);
}
.zalgo-box {
  background-color: #5865f2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.zalgo-box:hover {
  outline: solid 1px #5865f2;
  box-shadow: 3px 6px 7px #080808;
}
</style>
