<template>
  <div id='main'>
    <section class='section is-medium'>
      <h1 class='title is-1 has-text-white'>Zalgo staff</h1>
      <h2 class='subtitle has-text-white'>The team behind Zalgo</h2>
      <div class='staff'>
        <div :key='index' v-for='(member, index) in staff'>
          <div class='columns card-staff is-align-content-center is-align-items-center'>
            <div class='column is-one-third'>
              <img :src='member.avatarUrl' :alt='member.userUsername' draggable='false'>
            </div>
            <div class='column'>
              <h3 class='name'>{{ member.userUsername }}</h3>
              <div class='role'>{{ member.role }}</div>
            </div>
          </div>
        </div>
        <div class='error has-text-white' id='error'>Error, can't see members.</div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.staff {
  display: grid;
  margin: 6rem auto;
  max-width: 1200px;
  gap: 3rem;
  grid-auto-columns: 1fr;
  grid-template-areas:
        "card card card";
}

h1, h2 {
  text-align: center;
}

img {
  border-radius: 50%;
}

@media (max-width: 900px) {
  .staff {
    grid-template-areas:
          "card";
  }
}

@media (max-width: 1200px) {
  .staff {
    grid-template-areas:
          "card card";
  }
}

.card-staff {
  background-color: #5865f2;
  color: white;
  border-radius: 10px;
  user-select: none;
}

.role {
  background-color: #171717;
  border-radius: 5px;
  padding: .4rem .7rem;
  max-width: fit-content;
}

.column:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.error {
  display: none;
}
</style>
<script>
import TopPath from '../components/paths/Top.vue';
import BottomPath from '../components/paths/Bottom.vue';
import { mapGetters, mapMutations } from 'vuex';
import { USER, SHOW_NAVBAR, SET_NAV } from '../store/types';
import axios from 'axios';

export default {
  name: 'Staff',
  components: {
    TopPath,
    BottomPath
  },
  data() {
    return {
      staff: []
    };
  },
  mounted() {
    axios.get('https://websiteapi.zalgo.fr/staff', {
    }).then(res => {
      this.staff = res.data.data.staff;
    }).catch(err => {
      document.getElementById('error').style.display = 'block';
      console.log(err);
    });
  },
  methods: {
    ...mapMutations({
      displayNavbar: SET_NAV
    })
  },
  computed: {
    ...mapGetters({
      user: USER,
      showNavbar: SHOW_NAVBAR
    })
  }
};
</script>
