import api from '../api';
import { createStore } from 'vuex';
import {
  ACTUAL_GUILD,
  FETCH_GUILD,
  FETCH_USER,
  GUILDS,
  IS_LOGGED,
  LOGIN,
  LOGOUT,
  SAVE_GUILD,
  SET_GUILD,
  SET_NAV,
  SHOW_NAVBAR,
  TOKEN,
  USER,
  LOADING,
  START_LOADING,
  END_LOADING
} from './types';

const store = createStore({
  state: {
    //Global
    isLoading: false,
    displayNavbar: false,
    //Session
    user: null,
    guilds: null,
    token: null,
    actualGuild: null
  },
  getters: {
    [USER]: (state) => state.user,
    [TOKEN]: (state) => state.token,
    [GUILDS]: (state) => state.guilds,
    [ACTUAL_GUILD]: (state) => state.actualGuild,
    [IS_LOGGED]: (state) => (state.user ? true : false),
    [SHOW_NAVBAR]: (state) => state.displayNavbar,
    [ACTUAL_GUILD]: (state) => state.actualGuild,
    [LOADING]: (state) => state.isLoading
  },
  mutations: {
    [LOGIN]: (state, payload) => {
      state.user = payload.user;
      state.guilds = payload.guilds;
      state.token = payload.token;
    },
    [LOGOUT]: (state) => {
      state.user = null;
      state.guilds = null;
      state.token = null;
      state.actualGuild = null;
    },
    [SET_NAV]: (state) => {
      state.displayNavbar = state.displayNavbar === true ? false : true;
    },
    [SET_GUILD]: (state, payload) => {
      state.actualGuild = payload;
    },
    [START_LOADING]: (state) => {
      state.isLoading = true;
    },
    [END_LOADING]: (state) => {
      state.isLoading = false;
    }
  },
  actions: {
    [FETCH_USER]: (state, code) => {
      return new Promise((res, rej) => {
        api.authentication(code)
          .then(({ data }) => {
            state.commit(LOGIN, data);
            res();
          })
          .catch(() => {
            rej();
          });
      });
    },
    [FETCH_GUILD]: (state, id) => {
      return new Promise((res, rej) => {
        if (!state.getters[ACTUAL_GUILD] || state.getters[ACTUAL_GUILD].id !== id) {
          api.getGuild(id)
            .then((data) => {
              console.log(data);
              state.commit(SET_GUILD, data);
              res();
            })
            .catch(() => {
              state.commit(SET_GUILD, null);
              rej();
            });
        } else {
          res(state.getters[ACTUAL_GUILD]);
        }
      });
    },
    [SAVE_GUILD]: () => {
      //code
    }
  }
});

export default store;
