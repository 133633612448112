<template>
  <div id='main'>
    <section class='section is-medium'>
      <h1 class='title has-text-white'>Redirecting to Top.gg ...</h1>
    </section>
    <meta http-equiv='refresh' content='0;
          url=https://top.gg/bot/710135804714024962/vote'>
  </div>
</template>
<script>
import TopPath from '../components/paths/Top.vue';
import BottomPath from '../components/paths/Bottom.vue';

export default {
  name: 'Vote',
  components: {
    TopPath,
    BottomPath
  }
};
</script>
<style scoped>

</style>
