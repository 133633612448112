<template>
  <div id='main'>
    <section class='section is-small'>
      <h1 class='title is-1 has-text-white text-align-center'>Service status</h1>
    </section>
    <TopPath />
    <section class='section is-small shards_ctner'>
      <div class='shards-global'>
        <h2 class='title is-1 has-text-white'>Metrics</h2>
        <p class='title is-3 has-text-white'>{{ guilds }} guilds</p>
        <p class='title is-3 has-text-white'>{{ users }} users</p>
      </div>
      <div class='shards'>
        <div class='shard' v-for='(shard, index) in shards' :key=index :data-id=shard.id>
          <div class='box'>
            <span>ID {{ shard.id + 1 }}</span>
            <p>{{ shard.serverCount }} servers</p>
            <p>{{ shard.usersCount }} users</p>
            <hr>
            <p>{{ shard.ping }} ms</p>
            <p :style="[shard.status === 'Ready' ? {'color': 'green'} : {'color': 'red'}]">{{ shard.status }}</p>
          </div>
        </div>
      </div>
      <div class='inf pt-5'>
        <p class='has-text-white'>Available shards : {{  av_shards }}</p>
        <p class='has-text-white'>Unavailable shards : {{  un_shards }}</p>
      </div>
    </section>
    <BottomPath />
  </div>
</template>
<script>
import TopPath from '../components/paths/Top.vue';
import BottomPath from '../components/paths/Bottom.vue';
import axios from 'axios';

export default {
  name: 'Status',
  components: {
    TopPath,
    BottomPath
  },
  data() {
    return {
      guilds: 0,
      users: 0,
      shards: [],
      av_shards: 0,
      un_shards: 0
    };
  },
  methods: {
    checkAvailableShards: function(res) {
      this.av_shards = res.data.data.shards.length - (res.data.data.shards.status === "Ready")
      this.un_shards = this.av_shards - res.data.data.shards.length
    }
  },
  mounted() {
    axios.get(`https://websiteapi.zalgo.fr/stats`, {}).then(res => {
      this.checkAvailableShards(res)
      for (let i = 0; i < res.data.data.shards.length; i++) {
        this.shards.push(res.data.data.shards[i]);
      }
      for (let i = 0; i < res.data.data.shards.length; i++) {
        this.guilds += res.data.data.shards[i].serverCount;
      }
      for (let i = 0; i < res.data.data.shards.length; i++) {
        this.users += res.data.data.shards[i].usersCount;
      }
    }).catch(err => {
      this.guilds = '+ 7000';
      this.users = '+ 1 000 000';
      console.log(err);
    });
    setInterval(() => {
      axios.get('https://websiteapi.zalgo.fr/stats', {}).then(res => {
        this.av_shards = res.data.data.shards.length - (res.data.data.shards.status === "Ready")
        for (let i = 0; i < res.data.data.shards.length; i++) {
          this.shards[i].serverCount = res.data.data.shards[i].serverCount;
          this.shards[i].usersCount = res.data.data.shards[i].usersCount;
          this.shards[i].status = res.data.data.shards[i].status;
          this.shards[i].ping = res.data.data.shards[i].ping;
        }
      }).catch(err => {
        console.log(err);
      });
    }, 60000);
    setInterval(() => {
      axios.get('https://websiteapi.zalgo.fr/stats', {}).then(res => {
        let total_guilds;
        let total_users;
        for (let i = 0; i < res.data.data.shards.length; i++) {
          total_users += res.data.data.shards[i].usersCount;
        }
        for (let i = 0; i < res.data.data.shards.length; i++) {
          total_guilds = res.data.data.shards[i].serverCount
        }
      }).catch(err => {
        console.log(err);
      });
    }, 240000);
  }
};
</script>
<style scoped>
.shards_ctner {
  background-color: #5865f2;
  margin: -10px 0;
}

.text-align-center {
  text-align: center;
}

.shards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-top: 2rem;
}

.shards, .shards-global, .inf {
  margin: 0 auto;
  max-width: 1200px;
}

.shards-global {
  padding-bottom: 2rem;
}

.shard p,
.shard span {
  color: white;
}

.box {
  background-color: #181818;
}
</style>
