//Getters
export const USER = "USER";
export const TOKEN = "TOKEN";
export const GUILDS = "GUILDS";
export const IS_LOGGED = "IS_LOGGED";
export const ACTUAL_GUILD = "ACTUAL_GUILD";
export const SHOW_NAVBAR = "SHOW_NAVBAR";
export const LOADING = "LOADING";
//Mutation
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_NAV = "SET_NAV";
export const SET_GUILD = "SET_GUILD";
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
//Actions
export const FETCH_USER = "FETCH_USER";
export const FETCH_GUILD = "FETCH_GUILD";
export const SAVE_GUILD = "SAVE_GUILD";
