const axios = require('axios').default;

import store from './store/index';
import { TOKEN } from './store/types';

const instance = axios.create({
  baseURL: 'https://websiteapi.zalgo.fr/'
});

class Api {
  authentication(code) {
    return instance.get('/authentication', {
      params: { code }
    });
  }

  getGuild(id) {
    return new Promise((res, rej) => {
      instance
        .get('/guild', {
          params: { id },
          headers: {
            Authorization: `Bearer ${store.getters[TOKEN]}`
          }
        })
        .then((response) => {
          if (!response.data.error) {
            res(response.data);
          } else {
            rej(response.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }
}

const api = new Api();

export default api;
