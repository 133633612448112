<template>
  <!-- Footer component -->
  <section :class="{ 'is-blurred': showNavbar }">
    <TopPath />
    <section class='section is-small'>
      <div class='columns'>
        <div class='column'>
          <figure class='image is-128x128'>
            <img src='../assets/favicon.png' alt='Zalgo logo' />
          </figure>
        </div>
        <div class='column has-text-centered'>
          <h4 class='title'>Pages</h4>
          <ul>
            <li>
              <router-link to='/'>Home</router-link>
            </li>
            <li>
              <router-link to='/dashboard'>Dashboard</router-link>
            </li>
            <li>
              <router-link v-if='user' to='/logout'>Logout</router-link>
            </li>
          </ul>
        </div>
        <div class='column has-text-centered'>
          <h4 class='title'>Links</h4>
          <ul>
            <li>
              <router-link to='/vote'>Vote</router-link>
            </li>
            <li>
              <router-link to='/invite'>Invite</router-link>
            </li>
            <li>
              <router-link to='/privacy'>Privacy</router-link>
            </li>
            <li>
              <router-link to='/terms'>Terms</router-link>
            </li>
          </ul>
        </div>
        <div class='column has-text-centered'>
          <h4 class='title'>Support</h4>
          <ul>
            <li>
              <a href='https://discord.gg/3w3PVRTqdH' target='_blank' rel='noreferrer noopener'>Server</a>
            </li>
            <li>
              <router-link to='/status'>Status</router-link>
            </li>
            <li>
              <router-link to='/staff'>Staff</router-link>
            </li>
          </ul>
        </div>
      </div>
      <p class='has-text-centered credits'>
        &COPY; All copyrights Zalgo {{ date }}<br />Made with ❤️ ☕ by
        <a href='https://alphadevjs.fr' target='_blank' rel='noopener noreferrer'>Alphaa Studio</a>
        and
        <a href='https://thomasbnt.dev' target='_blank' rel='noopener noreferrer'>Thomas Bnt</a>
      </p>
    </section>
  </section>
</template>
<script>
import { mapState } from 'vuex';
import TopPath from './paths/Top.vue';

export default {
  name: 'Footer',
  components: {
    TopPath
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  data() {
    return {
      date: new Date().getFullYear()
    };
  }
};
</script>
<style scoped>
h4, a {
  color: white;
}
ul > li {
  line-height: 2.3rem;
}
ul > li > a {
  font-size: 1.4rem;
}

.section {
  background-color: rgb(88, 101, 242);
  margin: -7px 0;
  padding-bottom: 20px;
}
.column:first-of-type {
  align-self: center;
  text-align: -webkit-center;
}

.credits {
  padding-top: 1rem;
}

.credits > a {
  font-weight: bold;
}
</style>
